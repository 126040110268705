.Root {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

.Content {
  width: 100%;
  height: 100%;
  padding-top: 58px;
}

.Fixed {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
}
