.Root {
  margin-top: 15px;
  position: relative;
  height: min-content;
}

.Multiple {
  margin-bottom: 5px;
}

.Multiple > div span {
  font-size: 12px;
  line-height: 15px;
}
