@import './theme-fonts.css';

:root {
  --main-color: black;
  --secondary-color: #ffffff;
  --background-color: #e5e5e5;
  --text-color: #0062e0;
  --sidebar-background: #efefef;

  --main-color-40-opacity: rgba(0, 0, 0, 0.4);

  --color-gray-900: #222222;
  --color-gray-800: #3d3d3d;
  --color-gray-750: #4d4d4d;
  --color-gray-700: #666666;
  --color-gray-600: #888888;
  --color-gray-500: #999999;
  --color-gray-400: #bcbcbc;
  --color-gray-300: #dbdbdb;
  --color-gray-250: #e8e8e8;
  --color-gray-200: #efefef;
  --color-gray-150: #f5f5f5;
  --color-gray-100: #f8f8f8;

  --color-gray-white: #222222;

  --color-blue-1000: #002246;
  --color-blue-900: #00438b;
  --color-blue-800: #005dc2;
  --color-blue-700: #0070e8;
  --color-blue-600-primary: #007aff;
  --color-blue-500: #5dabff;
  --color-blue-400: #a2cfff;
  --color-blue-300: #d1e7ff;
  --color-blue-200: #deeeff;
  --color-blue-150: #e9f4ff;
  --color-blue-100: #f0f7ff;

  --color-purple-1000: #000b46;
  --color-purple-950: #1f2a7f;
  --color-purple-900: #2837a6;
  --color-purple-800: #2c3dc2;
  --color-purple-700: #3a4ddf;
  --color-purple-600-primary: #4359ff;
  --color-purple-550: #697aff;
  --color-purple-500: #8e9bff;
  --color-purple-400: #b4bdff;
  --color-purple-300: #d9deff;
  --color-purple-200: #e8ebff;
  --color-purple-150: #eff1ff;
  --color-purple-100: #f2f4ff;

  --color-green-100: #dff4ea;
  --color-green-300: #c2f0da;
  --color-green-400: #a7e2c6;
  --color-green-900: #149000;

  --color-orange-50: #fff0df;

  --color-brand-marketing-purple: #f0e1ff;
  --color-brand-marketing_purple_200: #c69def;
  --color-brand-marketing_purple_400: #613699;

  --color-others-alert: #e33922;
  --color-others-alert-100: #ffebe9;
  --color-others-alert-600: #e33922;

  --node-width: 200px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

button {
  background-color: transparent;
  color: inherit;
  border-width: 0;
  padding: 0;
  cursor: pointer;
}

figure {
  margin: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin: 0;
}

ul,
ol,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

p {
  margin: 0;
}

cite {
  font-style: normal;
}

fieldset {
  border-width: 0;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Roboto', 'Open Sans', sans-serif;
}

.create-page-enter {
  padding-top: 125%;
  background: transparent;
}

.create-page-enter-active {
  padding-top: 0;
  transition: all 400ms linear;
}

.create-page-exit {
  background: #e5e5e5;
  padding-top: 0;
}

.create-page-exit-active {
  padding-top: 125%;
  transition: all 400ms linear;
  background: transparent;
}

.edit-new-page-exit {
  background: #e5e5e5;
  padding-top: 0;
}

.edit-new-page-exit-active {
  padding-top: 125%;
  transition: all 400ms linear;
  background: transparent;
}
