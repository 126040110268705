.Attachment {
  position: relative;
}

.Attachment .AttachmentNode {
  border-radius: 13px;
}

.AttachmentNode::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--main-color);
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  z-index: 2;
}

.AttachmentNode:hover::before {
  opacity: 0.15;
}

.AttachmentBorder {
  box-shadow: 0 0 0 1px var(--secondary-color);
  border-radius: 13px;
}

.AttachmentNode div,
.AttachmentNode span,
.AttachmentNode img {
  border-radius: unset !important;
  border: none !important;
}

.PDFBorder {
  box-shadow: 0 0 0 1px var(--color-gray-150);
}

.AttachmentVideo > div > div > span {
  font-size: 65px;
}

.WithInactiveOverlay {
  opacity: 0.5;
}

.Multiple.AttachmentText h3,
.Multiple.AttachmentText p {
  font-size: 12px;
  margin-bottom: 3px;
  line-height: 14px;
}

.Multiple.AttachmentImage div:has(> span),
.Multiple.AttachmentPdf div:has(> span),
.Multiple.AttachmentText > div > div,
.Multiple.AttachmentAudio > div > div,
.Multiple.AttachmentVideo > div > div > div:has(> span) {
  padding: 10px;
}

.Multiple.AttachmentFolder span:has(> span) {
  padding-left: 10px;
}

.Multiple.AttachmentPdf div:has(> span) {
  align-items: center;
}

.Multiple.AttachmentVideo span:has(> svg) {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  font-size: 45px;
  width: max-content;
}

.Multiple.AttachmentAudio svg {
  height: 15px !important;
  width: 80% !important;
}

.Multiple.AttachmentAudio div,
.Multiple.AttachmentText div {
  font-size: 12px;
  line-height: 14px;
}

.Multiple.AttachmentPage div:has(> span),
.Multiple.AttachmentPdf div:has(> span) {
  min-height: 50px;
  padding: 10px;
}

.Multiple.AttachmentPage span:has(> svg),
.Multiple.AttachmentPdf span:has(> svg) {
  font-size: 20px;
}

.Multiple.AttachmentText img {
  height: 18px;
  width: 18px;
}

.Multiple.AttachmentPage div:has(> span),
.Multiple.AttachmentPdf div:has(> span),
.Multiple.AttachmentText div {
  column-gap: 5px;
}
