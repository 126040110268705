.MoreAttachmentsOverlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color-40-opacity);
  color: var(--secondary-color);
  font-weight: 500;
  font-size: 18px;
  z-index: 3;
}
