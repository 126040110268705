.Root {
  position: relative;
}

.ImageWrapper {
  margin-top: 10px;
  height: calc(600px - 135px);

  display: flex;
  align-items: center;
  justify-content: center;
}

.FooterControl {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.Image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;

  cursor: pointer;
}

.CameraIcon {
  position: absolute;
  width: 32px;
  height: 32px;
  bottom: 12px;
  right: 12px;
  padding: 8px;
  border-radius: 50%;
  background-color: white;
  background: rgba(34, 34, 34, 0.2);
}

.CameraIcon:hover {
  background: rgba(34, 34, 34, 0.4);
}

.UploadProgressBarWrapper {
  display: flex;
  justify-content: center;
  height: 360px;
  align-items: center;
}