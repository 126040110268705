.NotFound {
  display: flex;
  height: 64px;
  align-items: center;
  padding: 0 20px 0 20px;
  gap: 10px;
  border-radius: 13px;
  background-color: var(--color-gray-150);
}

.NotFoundText {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-gray-600);
}

.Multiple {
  height: 100%;
  width: 162px;
  padding: 0 15px 0 15px;
}

.Multiple .NotFoundText {
  font-size: 12px;
}

div.Multiple.NotFound div.MultipleIcon {
  padding: 0;
}